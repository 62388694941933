import { graphql } from "gatsby";
import React from "react";
import Page from "../components/Layout/Page";
import { PHOTOSETS } from "../routes";
import { TitledImage } from "../pages/today-is-sunday";
import TextBlock from "../components/Elements/TextBlock";
import Count from "../components/Elements/Count";
import pageColors from "../data/pageColors";
import ExternalLink from "../components/Elements/ExternalLink";

const Photoset = ({ data: { flickrPhotoset: photoset } }) => (
    <Page to={PHOTOSETS} title={photoset.title}>
        <TextBlock
            padding={5}
            backgroundColor={pageColors.photography + "-600"}
            textColor="white"
            className={"hidden md:block"}
        >
            <h1 className={"text-3xl text-center"}>{photoset.title}</h1>
        </TextBlock>
        <Count backgroundColor={pageColors.photography + `-800`}>
            {photoset.photos.length} photos
        </Count>
        {photoset.photos.map((photo, key) => {
            const [, title] = photo.title.includes("(")
                ? photo.title.replace(")", "").split("(")
                : photo.title.split(" - ");

            return (
                <TitledImage
                    key={photo.id}
                    title={title}
                    subtitle={String(key + 1).padStart(2, "0")}
                >
                    <ExternalLink href={photo.sizes.large}>
                        <img
                            src={photo.sizes.large}
                            alt={photo.title}
                            className={"shadow-2xl"}
                        />
                    </ExternalLink>
                </TitledImage>
            );
        })}
    </Page>
);

export const query = graphql`
    query PhotosetQuery($flickrId: String!) {
        flickrPhotoset(flickrId: { eq: $flickrId }) {
            id
            title
            date_create
            photos {
                id
                title
                sizes {
                    large
                }
            }
        }
    }
`;

export default Photoset;
